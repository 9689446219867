import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getRaceListRequest, getTeeListRequest, issueDataRequest } from "../../../api/controller/RaceKitController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";
import { Badge } from "reactstrap";

export const raceKitListState = atom({
    key: 'raceKitListState',
    default: [],
})
export const raceKitRaceState = atom({
	key: 'raceKitRaceState',
	default:{
		data:[],
		ovf:false,
	}
})
export const raceKitTeeState = atom({
	key: 'raceKitTeeState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest, closeOnSubmit: true }, { name: 'Issue Race Kit', action: issueDataRequest, closeOnSubmit: true } ],
    view: [ { name: 'Default View' } ],
}

export const raceKitDataStructureState = selector({
    key: 'raceKitDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Name',
                type: 'text',
                order: { table: 1, form: 1 }, 
                section: { id: 1, label: "General", isDisplayed: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Id Number',
                type: 'text',
                order: { table: 2, form: 2 }, 
                table: { maxWidth: 130 },
                section: { id: 1, label: "General" },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Email',
                type: 'email',
                order: { form: 2.01 }, 
                section: { id: 1, label: "General" },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Phone Number',
                type: 'text',
                order: { table: 2.1, form: 2.1 }, 
                table: { maxWidth: 150 },
                section: { id: 1, label: "General" },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
            },
            {
                label: "Date Of Birth",
                type: "datepicker",
                order: { form: 2.2 },
                section: { id: 1, label: "General" },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, dateFormat:"YYYY-MM-DD", options: { altInput: true, altFormat: "F j, Y", dateFormat: "Y-m-d", maxDate: 'today' } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            },
			{ 
                label: 'Gender',
                type: 'select',
                order: { form: 2.3 }, 
                rootValue: true,
                section: { id: 1, label: "General" },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
				list: {
                    ovf: false,
                    data: [
                        { label: 'Male', value: 'Male' },
                        { label: 'Female', value: 'Female' },
                    ]
                },
            },
			{ 
                label: 'Nationality',
                type: 'text',
                order: { form: 2.4 }, 
                section: { id: 1, label: "General" },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, subText: "3 letter" }], update: ['same_as:create.0', { enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 6 } }], 
            },
			{ 
                label: 'Club',
                type: 'text',
                order: { form: 2.4 }, 
                section: { id: 1, label: "General" },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Bib Number',
                type: 'text',
                order: { table: 3, form: 3 }, 
                table: { maxWidth: 130 },
                section: { id: 2, label: "Race Details", isDisplayed: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Bib Name',
                type: 'text',
                order: { form: 3.1 }, 
                section: { id: 2, label: "Race Details" },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Race',
                type: 'select',
                section: { id: 2, label: "Race Details" },
                order: { table: 4, form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
				list: get(raceKitRaceState),
				getRequest: getRaceListRequest,
            },
			{ 
                label: 'Remarks',
                type: 'text',
                order: { form: 4.1 }, 
                section: { id: 2, label: "Race Details" },
                create: [{ enabled: true, size: { xs: 12 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Race Tees',
                type: 'repeater',
                order: { form: 5 },
                section: { id: 2, label: "Race Details" },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'],
                children: [
                    { 
                		label: 'Tee',
                		type: 'select',
                		order: { form: 1 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 8 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
						list: get(raceKitTeeState),
						getRequest: getTeeListRequest,
            		},
					{ 
                		label: 'Quantity',
                		type: 'number',
                		order: { form: 2 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 4 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
            		},
					
                ]
            },
			{ 
                label: 'Bib Issued',
                type: 'switch',
                order: { form: 9 }, 
                section: { id: 3, label: "Issue Details", isDisplayed: true },
                create: [{ enabled: false }], update: ['same_as:create.0', { enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 6 } }], 
            },
			{ 
                label: 'Bib Issuer',
                type: 'select',
                order: { form: 9.1 }, 
                section: { id: 3, label: "Issue Details" },
                create: [{ enabled: false }], update: ['same_as:create.0', { enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'hidden_if:BibIssued,0' }], 
            },
			{ 
                label: 'Tee Issued',
                type: 'switch',
                order: { form: 9.4 }, 
                section: { id: 3, label: "Issue Details" },
                create: [{ enabled: false }], update: ['same_as:create.0', { enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 6 } }], 
            },
			{ 
                label: 'Tee Issuer',
                type: 'select',
                order: { form: 9.5 }, 
                section: { id: 3, label: "Issue Details" },
                create: [{ enabled: false }], update: ['same_as:create.0', { enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'hidden_if:TeeIssued,0' }], 
            },
			{ 
                label: 'Tee',
                field: 'tee_size',
                type: 'text',
                order: { table: 6, }, 
                section: { id: 3, label: "Issue Details" },
                table: { maxWidth: 130 },
                create: [{ enabled: false }], update: ['same_as:create.0', { enabled: false }], view: [{ enabled: false }], 
            },
			{ 
                id: "STATUS",
                label: 'Status',
                type: 'text',
                order: { table: 7, form: 9.9 }, 
                section: { id: 3, label: "Issue Details" },
                table: { maxWidth: 130, customRender: (value) => (
                    <Badge title={value} color={value==="ISSUED" ? "success" : (value === "BIB ISSUED" ? "warning" : "info")} className={"bg-"+(value==="ISSUED" ? "success" : (value === "BIB ISSUED" ? "warning" : "info"))+" font-size-14"} style={{padding: "5px 10px"}}>
                        {value}
                    </Badge> 
                ) },
                create: [{ enabled: false }], update: ['same_as:create.0', { enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 12 } }], 
            },


            
            { 
                id: 'RN01',
                label: 'Receiver Name',
                type: 'text',
                order: { form: 10 }, 
                section: { id: 3, label: "Issue Details" },
                create: [{ enabled: false }], update: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 12 }  }], view: [{ enabled: true, size: { xs: 12, md: 4 }, validations: 'hidden_if:BibIssued,0' }], 
            },
			{ 
                label: 'Receiver Id Number',
                type: 'text',
                order: { form: 11 }, 
                section: { id: 3, label: "Issue Details" },
                create: [{ enabled: false }], update: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 12 }  }], view: [{ enabled: true, size: { xs: 12, md: 4 }, validations: 'hidden_if:BibIssued,0' }], 
            },
			{ 
                label: 'Receiver Phone',
                type: 'text',
                order: { form: 12 }, 
                section: { id: 3, label: "Issue Details" },
                create: [{ enabled: false }], update: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 12 }  }], view: [{ enabled: true, size: { xs: 12, md: 4 }, validations: 'hidden_if:BibIssued,0' }], 
            },





            
            { 
                label: 'Chip Start Time',
                type: 'text',
                order: { form: 100 }, 
                section: { id: 4, label: "Timing Details", isDisplayed: true },
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 4 } }], view: ['same_as:update.0'], 
            },
            { 
                label: 'Chip Time',
                type: 'text',
                order: { form: 101 }, 
                section: { id: 4, label: "Timing Details" },
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 4 } }], view: ['same_as:update.0'], 
            },
            { 
                label: 'Gun Time',
                type: 'text',
                order: { form: 102 }, 
                section: { id: 4, label: "Timing Details" },
                create: [{ enabled: false }], update: [{ enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 4 } }], 
            },
            { 
                label: 'Overall Place',
                type: 'number',
                order: { form: 103 }, 
                section: { id: 4, label: "Timing Details" },
                create: [{ enabled: false }], update: [{ enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 4 } }], 
            },
            { 
                label: 'Gender Place',
                type: 'number',
                order: { form: 104 }, 
                section: { id: 4, label: "Timing Details" },
                create: [{ enabled: false }], update: [{ enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 4 } }], 
            },
            { 
                label: 'Age Category Place',
                type: 'number',
                order: { form: 105 }, 
                section: { id: 4, label: "Timing Details" },
                create: [{ enabled: false }], update: [{ enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 4 } }], 
            },
            { 
                label: 'Age Category',
                type: 'select',
                order: { form: 106 }, 
                section: { id: 4, label: "Timing Details" },
                create: [{ enabled: false }], update: [{ enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 4 } }], 
            },
            { 
                label: 'Is Disqualified',
                type: 'switch',
                order: { form: 107 }, 
                section: { id: 4, label: "Timing Details" },
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 4 } }], view: ['same_as:update.0'], 
            },
            { 
                label: 'Timing Status',
                type: 'text',
                order: { form: 108 }, 
                section: { id: 4, label: "Timing Details" },
                create: [{ enabled: false }], update: [{ enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 4 } }], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
