
export const handleSocket = (currentData, newData, setData, selectKeys={value: 'id', label: 'name'}) => {
    const incomingID = newData.event === "Deleted" ? newData.data : newData.data.id
    const currentIDKey = newData.group === "Select" ? "value" : "id"
    const parsedData = newData.event === "Deleted" || newData.group !== "Select" ? newData.data : { label: newData.data[selectKeys.label], value: newData.data[selectKeys.value] }
    let tempArr = currentData?.data || currentData
    let dataToSet = []
    if(newData.event === "Deleted"){
        dataToSet = {data: tempArr.filter(i=>parseInt(i[currentIDKey]) !== parseInt(incomingID))}
    } else if(newData.event === "Created"){
        dataToSet = {data: [newData?.data, ...tempArr]}
    } else if(newData.event === "Updated"){
        dataToSet = {data: tempArr.map(i=>parseInt(i[currentIDKey]) !== parseInt(incomingID) ? i : parsedData)}
    }
    if(dataToSet && currentData?.data){
        setData({...currentData, ...dataToSet})
    } else {
        setData(dataToSet.data)
    }
    
}

export const handleGridSocket = (newData, gridApi, setGridApi, isDisplayedRequest=false, searchParams='', selectKeys={value: 'id', label: 'name'}) => {
    const incomingID = newData.event === "Deleted" ? newData.data : newData.data.id
    const parsedData = newData.event === "Deleted" || newData.group !== "Select" ? newData.data : { label: newData.data[selectKeys.label], value: newData.data[selectKeys.value] }
    if(newData.event === "Deleted"){
        if(gridApi.getRowNode(incomingID)){
            gridApi.applyTransaction({remove: [{id: incomingID}]})
        }
    } else if(newData.event === "Created"){
        if(isDisplayedRequest){
            isDisplayedRequest({id: incomingID, SearchQuery: searchParams}).then(response=>{
                if(response?.IsDisplayed){
                    gridApi.applyTransaction({add: [parsedData], addIndex: 0})
                }
            })
        } else {
            gridApi.applyTransaction({add: [parsedData], addIndex: 0})
        }
    } else if(newData.event === "Updated"){
        if(typeof(parsedData) === 'number'){
            handleGridSocket({...newData, event: "Deleted"}, gridApi, setGridApi, isDisplayedRequest, searchParams, selectKeys)
        } else if(gridApi.getRowNode(incomingID)){
            gridApi.getRowNode(incomingID).setData(parsedData)
            if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes()[0]){
                gridApi.getSelectedNodes()[0].setSelected(false)
                setTimeout(()=>{
                    gridApi.getRowNode(incomingID).setSelected(true)
                }, [50])
            }
        } else {
            handleGridSocket({...newData, event: "Created"}, gridApi, setGridApi, isDisplayedRequest, searchParams, selectKeys)
        }
    }
    setGridApi(gridApi)
    
}