
import http from "../core/http";
const group = 'ext/timings';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}
export const findDataRequest =  async (data) => {
    return await http.post(`${group}/find`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getRaceListRequest =  async (data) => {
    return await http.post(`${group}/getRace`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getAgeCategoryListRequest =  async (data) => {
    return await http.post(`${group}/getAgeCategory`, data).then(res => res.data).catch(http.defaultCatch);
}
