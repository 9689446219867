import { handleBlobResponse } from "../../custom/helpers/HandleResponse";
import http from "../core/http";
const group = 'tees';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const isDisplayedRequest =  async (data) => {
    return await http.post(`${group}/isDisplayed`, data).then(res => res.data).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getTeeTypeListRequest =  async (data) => {
	return await http.post(`${group}/getTeeTypeList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getTeeSizeListRequest =  async (data) => {
	return await http.post(`${group}/getTeeSizeList`, data).then(res => res.data).catch(http.defaultCatch);
}

export const exportDataListRequest =  async (data) => {
    const response = await http({url: `${group}/export`, method: "POST", data: data, responseType: "blob"}).then(res => res.data).catch(http.defaultCatch);
    handleBlobResponse(response, (data?.DownloadName || 'Tee-Report.xlsx'));
}
