import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getTeeTypeListRequest, getTeeSizeListRequest } from "../../../api/controller/TeeController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const teeListState = atom({
    key: 'teeListState',
    default: [],
})
export const teeTeeTypeState = atom({
	key: 'teeTeeTypeState',
	default:{
		data:[],
		ovf:false,
	}
})
export const teeTeeSizeState = atom({
	key: 'teeTeeSizeState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "md", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest, closeOnSubmit: true } ],
    view: [ { name: 'Default View' } ],
}

export const teeDataStructureState = selector({
    key: 'teeDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Tee Type',
                type: 'select',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(teeTeeTypeState),
				getRequest: getTeeTypeListRequest,
            },
			{ 
                label: 'Tee Size',
                type: 'select',
                order: { table: 2, form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                table: { maxWidth: 150 },
				list: get(teeTeeSizeState),
				getRequest: getTeeSizeListRequest,
            },
			{ 
                label: 'Price',
                type: 'number',
                order: { table: 3, form: 3 }, 
                table: { maxWidth: 130 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Total Stock',
                type: 'number',
                order: { form: 4 }, 
                table: { maxWidth: 130 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Issued',
                type: 'number',
                order: { table: 5, form: 5 }, 
                table: { maxWidth: 130 },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 }  }], 
            },
			{ 
                label: 'Race Kit Reserved',
                type: 'number',
                order: { table: 6, form: 6 }, 
                table: { maxWidth: 180 },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 }  }], 
            },
			{ 
                label: 'Race Kit Issued',
                type: 'number',
                order: { table: 7, form: 7 }, 
                table: { maxWidth: 160 },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 }  }], 
            },
			{ 
                label: 'Available',
                type: 'number',
                order: { table: 8, form: 8 }, 
                table: { maxWidth: 130 },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 }  }], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
