import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/RaceController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const raceListState = atom({
    key: 'raceListState',
    default: [],
})


export const modalConfigDefault = { size: "md", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest, closeOnSubmit: true } ],
    view: [ { name: 'Default View' } ],
}

export const raceDataStructureState = selector({
    key: 'raceDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Name',
                type: 'text',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
            { 
                label: 'Distance',
                type: 'number',
                order: { table: 1.1, form: 1.1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
            { 
                label: 'Gun Time',
                type: 'datepicker',
                order: { table: 2, form: 2 }, 
                table: { dateFormat: "MMM Do, YYYY | HH:mm:ss" },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, dateFormat:"YYYY-MM-DD HH:mm:ss", options: { enableTime: true, enableSeconds: true, time_24hr: true, altFormat: 'F j, Y H:i:S', dateFormat: 'Y-m-d H:i:S' }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
            { 
                label: 'Is Timed',
                type: 'switch',
                order: { table: 3, form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
            { 
                label: 'Timing Url',
                type: 'text',
                order: { form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'hidden_if:IsTimed,0'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
            { 
                label: 'Cn Bib Number',
                type: 'text',
                order: { form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'hidden_if:IsTimed,0'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
            { 
                label: 'Cn Chip Time',
                type: 'text',
                order: { form: 6 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'hidden_if:IsTimed,0'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
