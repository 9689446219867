import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getTeeListRequest } from "../../../api/controller/TeeIssueController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const teeIssueListState = atom({
    key: 'teeIssueListState',
    default: [],
})
export const teeIssueTeeState = atom({
	key: 'teeIssueTeeState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest, closeOnSubmit: true } ],
    view: [ { name: 'Default View' } ],
}

export const teeIssueDataStructureState = selector({
    key: 'teeIssueDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Tee',
                type: 'select',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(teeIssueTeeState),
				getRequest: getTeeListRequest,
            },
			{ 
                label: 'Quantity',
                type: 'number',
                order: { table: 2, form: 2 }, 
                table: { maxWidth: 130 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, default: 1 }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Price',
                type: 'number',
                order: { form: 3 }, 
                create: [{ enabled: false  }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 }  }], 
            },
			{ 
                label: 'Total',
                type: 'number',
                order: { table: 4, form: 4 }, 
                table: { maxWidth: 130 },
                create: [{ enabled: false  }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 }  }], 
            },
			{ 
                label: 'Buyer Name',
                type: 'text',
                order: { table: 5, form: 5 }, 
                table: { maxWidth: 200 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Buyer Contact',
                type: 'text',
                order: { form: 6 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Remarks',
                type: 'text',
                order: { form: 7 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
