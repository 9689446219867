import React, { useEffect, useState } from 'react';

import { Alert, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Link, withRouter } from 'react-router-dom';
// import { AvField, AvForm } from 'availity-reactstrap-validation';

// import images
// import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo-light.png";
import logoDark from "../../assets/images/logo-dark.png";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { loginErrorState } from '../../state/ErrorState';
import { loginRequest, verifyLoginRequest } from '../../api/controller/AuthController'
import { passwordResetHashState, otpReferenceState } from '../../api/state/AuthState';
import { themeTypeState } from '../../state/GlobalState';
import { Loader } from '../../custom/components/Loader';


const CarouselPage = () => {
    return (
      <React.Fragment>
        <Col xl={9} style={{ backgroundColor:'#000'}}>
          <div className="auth-full-bg">
            <div className="w-100">
              <div className="bg-overlay"></div>
              
            </div>
          </div>
        </Col>
      </React.Fragment>
    )
}

const Login = ({history,handleAuthResponse}) =>  {
    const [error, setError] = useRecoilState(loginErrorState)
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('authUser')))
    const [rememberMe, setRememberMe] = useState(false)
    const setPasswordResetHash = useSetRecoilState(passwordResetHashState)
    const [otpReference, setOtpReference] = useRecoilState(otpReferenceState)
    const themeType = useRecoilValue(themeTypeState)
    const [form, setForm] = useState({
        email: '',
        password: '',
        OTP: '',
    })

    // handleValidSubmit
    const handleValidSubmit = async (event) => {
        event.preventDefault()
        setSubmitDisabled(true)
        let response = null
        if(otpReference === null){
            response = await loginRequest({email: user ? user.email : form.email, password: form.password})
        } else {
            response = await verifyLoginRequest({ reference: otpReference, otp: form.OTP })
        }
        
        if(response.decodedToken){
            handleAuthResponse(response, response.encodedToken, rememberMe, true)
        } else if(response.scp){
            localStorage.removeItem('authToken')
            setPasswordResetHash(response.hash)
            history.push('/change-password');
        } else if(response.otp){
            localStorage.removeItem('authToken')
            setOtpReference(response.Reference)
            setSubmitDisabled(false)
        } else if (response.message){
            setError(response.message)
            setSubmitDisabled(false)
        } else {
            setError(response.Message)
            setSubmitDisabled(false)
        }
    }

    useEffect(() => {
        setError("");
    }, [setError])

    return (
        <React.Fragment>
            <Container fluid className="p-0" style={{overflow:'hidden'}}>
                <Loader loading={submitDisabled} transparent />
                <Row className="no-gutters">
                    <CarouselPage  /> 
                    <Col xl={3}>
                    <div className="auth-full-page-content p-md-5 p-4">
                        <div className="w-100">
                        <div className="d-flex flex-column h-100">
                            <div className="my-auto mt-5">
                            <div align="center" className="mb-4 mb-md-5">
                            <img
                                src={themeType === 'dark' ? logo : logoDark}
                                alt="irunners-logo"
                                width="100%"
                                className='logo-light'
                                style={{justifyContent:'center', aligneItem:'center'}} 
                                />
                            </div>

                           
                                 
                            <Form className="form-horizontal" onSubmit={handleValidSubmit}>

                                {error ? <Alert color="danger">{error}</Alert> : null}
                                {otpReference === null ? (
                                    <>
                                        {user ? (
                                            <div className="user-thumb text-center mb-4">
                                                <img src={user.avatar} className="rounded-circle img-thumbnail avatar-md" alt="thumbnail"/>
                                                <h5 className={"font-size-15 mt-3 " + (themeType === 'dark' ? 'text-white' : '')}>{user.name}</h5>
                                            </div>
                                        ) : (
                                            <FormGroup>
                                                <Label>Email</Label>
                                                <Input value={form.email} onChange={(e)=>setForm({...form, email: e.target.value})} name="email" label="Email" className="form-control" placeholder="Enter email" type="email" required />
                                            </FormGroup>
                                        )}

                                        <FormGroup>
                                            <Label>Password</Label>
                                            <Input value={form.password} onChange={(e)=>setForm({...form, password: e.target.value})} name="password" label="Password" type="password" required placeholder="Enter Password" />
                                        </FormGroup>
                                        {!user ? (
                                            <div className="custom-control custom-checkbox" onClick={()=>setRememberMe(!rememberMe)}>
                                                <Input type="checkbox" className="custom-control-input" onChange={()=>false} checked={rememberMe} />
                                                <Label className="custom-control-label" htmlFor="customControlInline">  &nbsp;Remember me</Label>
                                            </div>
                                        ) : null}
                                    </>
                                ) : (
                                    <FormGroup>
                                        <Label>OTP</Label>
                                        <Input value={form.OTP} onChange={(e)=>setForm({...form, OTP: e.target.value})} name="OTP" label="We sent an OTP via text" className="form-control" placeholder="Enter OTP" type="number" required />
                                    </FormGroup>
                                )}



                                <div className="mt-4">
                                    <button disabled={submitDisabled} className="btn btn-primary btn-block waves-effect waves-light w-100" type="submit">{otpReference === null ? user ? "Unlock" : "Login" : "Verify"}</button>
                                </div>

                                <div className="mt-4 text-center">
                                    {otpReference === null ? <Link to="/forgot-password" className={(themeType === 'dark' ? 'text-white' : 'text-muted')}><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                    : <p>Go back to{" "}<span style={{cursor: "pointer"}} className={"font-weight-medium " + (themeType === 'dark' ? 'text-white' : 'text-primary')} onClick={()=>{localStorage.removeItem('authUser');setUser(null);setOtpReference(null)}}>Login</span>{" "}</p> }
                                    
                                </div>
                                </Form>
                                 
                                  
                            </div>
                            {user ? (
                                <div className="mt-5 text-center">
                                    <p>Not you? return to <Link onClick={()=>{localStorage.removeItem('authUser');setUser(null)}} to="/" className="font-weight-medium text-primary"> Sign In </Link> </p>
                                </div>
                            ) : null}
                            <div className="mt-4 mt-md-5 text-center">
                            <p className="mb-0">
                                © {new Date().getFullYear()} irunners {""}<br/> 
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </Col>
                </Row>
                </Container>
        </React.Fragment>
    );
}

export default withRouter(Login);
